function Header({text1, text2}){
    return(
        <div className="app-header" id="header">
            <div className="app-header-1">
                {text1}
            </div>
            <div className="app-header-2">
            {text2!= "" ? "· " + text2: ""}
            </div>
        </div>
    )
}



export default Header
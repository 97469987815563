import '../App.css';
import Account from '../Account';

import React, { useState } from "react";
import TrialComponent from './TrialComponent';


function MyTrialsComponent({addPatientFunction}){

    const [trialOn, setTrialOn] = useState(0);

    function TrialComponents(){
        return Account.trials.map((val, ind) => {
            return <TrialComponent trial={val} open={ind == trialOn} openFunction={() => setTrialOn(ind)}/>
        })
    }

    function SiteComponents(){
        if (Account.sites[Account.trialIds[trialOn]].length == 0){
            return <div className='my-trials-site'>You are not currently registered at any sites for this trial</div>
        }
        return Account.sites[Account.trialIds[trialOn]].map((val, ind) => {
            return <div className='my-trials-site'> {"· " + val}</div>
        })
    }
    
    return (
    <div className='my-trials-div' id='my-trials-div'>
        <div className='description-div'>
            <div className='description-div-text1'>
                Trials You are Assigned To
            </div>
            <div className='description-div-text2'>
                To see details about one of your specific trials, select it from the list below
            </div>
        </div>
        <div className='my-trials-list-div'>
            <div className='my-trials-title'>Trial</div>
            <div className='my-trials-scroll'>
            {TrialComponents()}
            </div>
        </div>
        <div className='my-trial-info-div'>
            <div className='my-trials-title-2'>Details</div>
            <div className='my-trials-name'>{Account.trials[trialOn].name}</div>
            <a className='my-trials-add-patient' href='#' onClick={() => addPatientFunction(trialOn)}>Add Patient</a>
            {Account.trials[trialOn].description != undefined?
            <div className='my-trials-description'>{Account.trials[trialOn].description}</div>:<div/>}    
            <div className='my-trials-patients-recruited'>You have recruited <font color="#057DB8">{Account.patientsRecruited[Account.trialIds[trialOn]]}</font> Patients to this study</div>
            <div className='my-trials-sites-title'>Your Active Sites:</div>
            {SiteComponents()}
            
        </div>
    </div>)

}

export default MyTrialsComponent;
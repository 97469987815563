import React, { useState } from "react";

import arrow from "../imgs/arrowright.svg"


function TrialComponent({trial, open, openFunction}){

    //index = 0, top, index = 1, mid, index = 2, bottom
    
    return(
        <a href="#" className={open?"trial-div-open":"trial-div"} onClick={openFunction} >
            <img className="trial-img" src={trial.logoURL}/>
            <div className="trial-text-div">
                <div className="trial-top-div">
                    {trial.nameShorthand}
                </div>
                <div className="trial-bottom-div">
                    {trial.name}
                </div>
            </div>
            <img className="trial-img-2" src={open?arrow:undefined}/>
            
            
        </a>
    )
}

export default TrialComponent;
import React, { useState } from "react";
import accountImg from "../imgs/singleAccount.svg"
import manageAccountImg from "../imgs/manage_account.svg"
import Account from "../Account"

import { getAuth, signInWithEmailAndPassword, updatePassword } from "firebase/auth";

import {toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';


function ChangePasswordComponent({changeFunction, resetFunction, setHeader}){

    const[criteriaMet, setCriteriaMet] = useState([false, false, false, false]);
    const[text1, setText1] = useState("");
    const[text2, setText2] = useState("");
    const[text3, setText3] = useState("");
    const [resetOpen, setResetOpen] = useState(false);

    function passwordChangedHandler1(event){
        const string = event.target.value;
        setText1(string);
    }

    function passwordChangedHandler2(event){
        const string = event.target.value;
        setText2(string);
        const length = string.length;
        const bool = length > 7 && length < 18;
        const number = /\d/.test(string);
        const upper = /[A-Z]/.test(string);
        const lower = /[a-z]/.test(string);
        const special = /[^A-Za-z0-9]/.test(string);
        const newCriteria = [bool, number, upper && lower, special];
        setCriteriaMet(newCriteria)
    }
      
    function passwordChangedHandler3(event){
        const string = event.target.value;
        setText3(string);
    }

    function changePassword(){
        if (criteriaMet.includes(false)){
            toast("Your new password does not meet all criteria");
            return;
        }
        if (text2 !== text3){
            toast("Your new passwords do not match");
            return;
        }
        if (text1 == text2){
            toast("Your new password cannot be the same as your old password");
            return;
        }
        const auth = getAuth();
        signInWithEmailAndPassword(auth, Account.email, text1)
        .then((userCredential) => {
            const user = userCredential.user;
            updatePassword(user, text2).then(() => {
                toast("Your password has been changed");
                changeFunction();
              }).catch((error) => {
                toast("An Error has occured");
              });
        })
        .catch((error) => {
            toast("You old password is incorrct");
        });

    }

    return <div className="change-password-div">
        {resetOpen? 
            <div>

            </div>:
            <div>
                <div className='password-label-div'>Old Password</div>
                <input name="oldpassword" onChange={passwordChangedHandler1}type="password" className='old-password-input' placeholder="Old Password"></input>
                <a className='forgot-password-a-2' href="#" onClick={() => {
                    setResetOpen(true)
                    setHeader()
                    }}>
                Forgot Password?
                </a>
                <div className='password-label-div'>New Password</div>
                <input name="newpassword" onChange={passwordChangedHandler2} type="password" className='new-password-input' placeholder="New Password"></input>
                <div className='password-label-div'>Confirm New Password</div>
                <input name="newpassword2" onChange={passwordChangedHandler3} type="password" className='new-password-2-input' placeholder="Confirm New Password"></input>
                <div className='password-criteria-div'>Your Password must be between 8-17 characters</div> 
                <div className={criteriaMet[0]?'password-criteria-dot-green':'password-criteria-dot-red'}>·</div> 
                <div className='password-criteria-div'>Your Password must include a number (0-9)</div> 
                <div className={criteriaMet[1]?'password-criteria-dot-green':'password-criteria-dot-red'}>·</div> 
                <div className='password-criteria-div'>Your Password must contain at least one uppercase and one lowercase letter</div> 
                <div className={criteriaMet[2]?'password-criteria-dot-green':'password-criteria-dot-red'}>·</div> 
                <div className='password-criteria-div'>Your password must contain at least one special character</div> 
                <div className={criteriaMet[3]?'password-criteria-dot-green':'password-criteria-dot-red'}>·</div> 
                <a className='change-password-button' href="#" onClick={changePassword}>
                    Change Password
                </a>
            </div>
        }   
        </div>
}

export default ChangePasswordComponent;

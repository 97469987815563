import '../App.css';

import React, { useState } from "react";
import siteImg from "../imgs/condo.svg"
import ArrowDown from "../imgs/arrowdown.svg"
import Arrowup from "../imgs/arrowup.svg"


function SiteComponent({open, site, siteShorthand, onClickFunction}){



    return(
        <a className={open?"coordinator-a":"coordinator-a-closed"} href="#" onClick={onClickFunction}>
            <img className="account-img1" src={siteImg}/>
            <div className='account-info'>
                <div className='coordinator-comp1'>{site}</div>
                <div className='coordinator-comp2'>{siteShorthand}</div>
            </div>
            <img className="account-img2" src={open? Arrowup:ArrowDown}/>
        </a>
    )
}



export default SiteComponent;
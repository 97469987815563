import React, { useState } from "react";

import arrow from "../imgs/arrowright.svg"
import account from "../imgs/singleAccount.svg"
import accountBlue from "../imgs/singleAccountBlue.svg"


function PatientComponent({id, date, open, openFunction}){

    return(
        <a href="#" className={open?"patient-div-open":"patient-div"} onClick={openFunction} >
            <img className="patient-img" src={open?account:accountBlue}/>
            <div className="patient-text-div">
                <div className="patient-top-div">
                    Patient ID:
                </div>
                <div className="patient-middle-div">
                    {id}
                </div>
                <div className="patient-bottom-div">
                    {date}
                </div>
            </div>
            <img className="patient-img-2" src={open?arrow:undefined}/>
            
            
        </a>
    )
}

export default PatientComponent;
import '../App.css';

import React from "react";
import LoadingGif from "../imgs/loading.gif"

function LoadingComponent({text}){
    return <div className={"loading-div"}>
        <img className="loading-img" src={LoadingGif}/>
        <div className='loading-text'>
            {text}
        </div>
    </div>
}

export default LoadingComponent;
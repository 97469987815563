import '../App.css';
import Account from '../Account';

import React, { useState } from "react";
import PatientComponent from './PatientComponent';



function MyAccountComponent({}){

    const [patientOn, setPatientOn] = useState(0); 


    function PatientComponents(){
        return Account.log.toReversed().map((val, ind) => {
            return <PatientComponent id = {val["patient id"]} date = {val["time"].toDate().toDateString()}
            open={ind == patientOn} openFunction={() => setPatientOn(ind)}/>
        })
    }

    
    return (
    <div className='my-account-div' id='my-account-div'>
        <div className='my-account-list-div'>
            <div className='my-account-title'>Patient</div>
            <div className='my-account-scroll'>
            {PatientComponents()}
            </div>
        </div>
        <div className='my-account-info-div'>
            <div className='my-trials-title-2'>Information</div>
            <div className='patient-label-div'>Patient Id:</div>
            <div className='patient-info-div'> {Account.log[patientOn]["patient id"]}</div>
            <div className='patient-label-div'>Trial:</div>
            <div className='patient-info-div'> {Account.log[patientOn]["trial name"]}</div>

            <div className='patient-label-div'>Site:</div>
            <div className='patient-info-div'> {Account.log[patientOn]["site"]}</div>

            <div className='patient-label-div'>Time Randomized:</div>
            <div className='patient-info-div'> {Account.log[patientOn]["time"].toDate().toString()}</div>

            <div className='patient-label-div'>{Account.log[patientOn]["variable1 name"]}</div>
            <div className='patient-info-div'> {Account.log[patientOn]["variable1"]}</div>
            
            <div className='patient-label-div'>{Account.log[patientOn]["variable2 name"]}</div>
            <div className='patient-info-div'> {Account.log[patientOn]["variable2"]}</div>
            
            <div className='patient-label-div'>{Account.log[patientOn]["variable3 name"]}</div>
            <div className='patient-info-div'> {Account.log[patientOn]["variable3"]}</div>
            
            <div className='patient-label-div'>{Account.log[patientOn]["variable4 name"]}</div>
            <div className='patient-info-div'> {Account.log[patientOn]["variable4"]}</div>
            

            
        </div>

    </div>)

}

export default MyAccountComponent;
import '../App.css';

import React, { useState } from "react";
import remove from "../imgs/remove.svg"
import save from "../imgs/save.svg"
import removeSite from "../imgs/remove_site.png"




function RemoveAndSaveComponent({saveFunction, removeFunction, type}){    
    return(
        <div className='remove-and-save-buttons'>
            <a className='remove-coord-a' href='#' onClick={removeFunction}>
                <div className='remove-coord-div'>{type == "site"?"Remove Site":"Remove Coordinator"}</div>                
                <img className='remove-coord-img' src={type == "site"?removeSite:remove}/>
            </a>
            <a className='save-changes-a' href='#' onClick={saveFunction}>
                <div className='save-changes-div'>Save Changes</div>                
                <img className='save-changes-img' src={save}/>
            </a>
        </div>
    )
}

export default RemoveAndSaveComponent;
import '../App.css';

import React, { useState } from "react";
import accountImg from "../imgs/account.svg"
import ArrowDown from "../imgs/arrowdown.svg"
import Arrowup from "../imgs/arrowup.svg"


function CoordinatorComponent({open, account, onClickFunction}){

    return(
        <a className={open?"coordinator-a":"coordinator-a-closed"} href="#" onClick={onClickFunction}>
            <img className="account-img1" src={accountImg}/>
            <div className='account-info'>
                <div className='coordinator-comp1'>{account.username}</div>
                <div className='coordinator-comp2'>{account.email}</div>
            </div>
            <img className="account-img2" src={open? Arrowup:ArrowDown}/>
        </a>
    )
}



export default CoordinatorComponent;
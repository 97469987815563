
import { initializeApp } from "firebase/app";
import {getFirestore} from "firebase/firestore";
import { getAnalytics } from "firebase/analytics";


class Firebase{
    static app;
    static db;
    static firebaseConfig = {
        apiKey: "AIzaSyDZMy_oby2L32uabLXtc5YYBeKSL5jOXqI",
        authDomain: "allocate-application.firebaseapp.com",
        projectId: "allocate-application",
        storageBucket: "allocate-application.appspot.com",
        messagingSenderId: "637473446992",
        appId: "1:637473446992:web:e2193f49d0382a0252c555",
        measurementId: "G-8P4ENZ3FXV"
    };

    static initialize(){
        Firebase.app = initializeApp(Firebase.firebaseConfig);
        Firebase.db = getFirestore(Firebase.app);
        Firebase.analytics = getAnalytics(Firebase.app);
    }  
}

export default Firebase;
import React, { useState } from "react";
import accountImg from "../imgs/singleAccount.svg"
import logoutImg from "../imgs/logout.svg"
import manageAccountImg from "../imgs/manage_account.svg"
import ArrowDown from "../imgs/arrowdown.svg"
import Arrowup from "../imgs/arrowup.svg"
import Account from "../Account"


function MyAccountDropdownComponent({changePasswordFunction, logOutFunction}){
    const [open, setIsOpen] = useState(false);

    function closeAndOpen(){
        if (open){
            document.getElementById("my-account-dropdown-comp").style.height = "55px";
            setIsOpen(false);
        }
        else{
            document.getElementById("my-account-dropdown-comp").style.height = "165px";
            setIsOpen(true);
        }
        
    }
    return <div className="my-account-dropdown-comp" id= "my-account-dropdown-comp">
        <a className={open?"my-account-a-open":"my-account-a"} href="#" onClick={() => {closeAndOpen()}}>
            <img className="my-account-img2" src={accountImg}/>
            <div className='my-account-info'>
                <div className='my-account-comp1'>{Account.username}</div>
                <div className='my-account-comp2'>{Account.email}</div>
            </div>
            <img className="my-account-img1" src={open? Arrowup:ArrowDown}/>
        </a>
        {open?<a className="manage-account-a" href="#" onClick={() =>{
            closeAndOpen();
            changePasswordFunction();
        }}>
            <img className="my-account-img2" src={manageAccountImg}/>
            <a className="sign-out-text">Change Password</a>
        </a>:<div/>}
        {open?<a className="sign-out-a" href="#" onClick={() =>{
            closeAndOpen();
            logOutFunction();
        }}>
            <img className="my-account-img2" src={logoutImg}/>
            <a className="sign-out-text">Sign Out</a>
        </a>:<div/>}
        
    </div>
}

export default MyAccountDropdownComponent;
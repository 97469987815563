import '../App.css';

import React, { useState } from "react";
import box from "../imgs/checkoutline.svg"
import checkBox from "../imgs/checkbox.svg"

function CheckComponent({name, checked, onClickFunction}){
    console.log("check here");
    return(
        <a className="site-a" href="#" onClick={onClickFunction}>
            <img className="site-img" src={checked? checkBox: box}/>
            <div className='site-div' style={{color: checked?'#000000':'#818181'}}> {name}</div>
        </a>
    )
}

export default CheckComponent;